"use client";

import React from "react";

import { Device } from "@/types/Shop.type";
import { BrandJson } from "@/types/Brand.type";
import Website from "@/common/constants/webstite";

import Image from "@/components/ui/Image";
import LinkElement from "@/components/LinkElement";
import ImageConstant from "@/common/constants/image";

const BlogCard = ({ data, device }: { data: BrandJson; device: Device }) => {
  return (
    <LinkElement
      prefetch={false}
      href={`${Website.LINK.PAGE.THUONG_HIEU_NUOC_HOA}/${data.handle}`}
      className="w-full"
    >
      <Image
        quality={100}
        width={400}
        height={200}
        alt="img"
        src={data?.images[0]?.url || ""}
        className="w-full h-[111px] md:h-[165px] object-cover"
        placeholder={"blur"}
        blurDataURL={ImageConstant.BlurDataURL}
      />
      <p className="mt-2 text-sm md:text-base font-semibold md:font-normal hover:text-yellow-500 transition-all text-gray-500 leading-[1.6] line-clamp-2">
        {data.title || ""}
      </p>
    </LinkElement>
  );
};

export default BlogCard;
