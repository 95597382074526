"use client";
import "swiper/css";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { BrandData } from "../Brand";
import LinkElement from "@/components/LinkElement";
import ImageConstant from "@/common/constants/image";
import ImageV2 from "@/components/ui/ImageV2";

type Props = {
	data: BrandData[];
};

const BrandSwipper = ({ data }: Props) => {
	const bannerBrandImage = [
		{
			id: "https://theme.hstatic.net/1000340570/1000964732/14/banner_brand_image_section_02.jpg",
		},
		{
			id: "https://theme.hstatic.net/1000340570/1000964732/14/banner_brand_image_section_01.jpg",
		},
	];
	return (
		<div className="w-[50%] hidden md:block min-w-[50%]">
			<Swiper
				spaceBetween={0}
				slidesPerView={1}
				loop
				autoplay={false}
				modules={[Autoplay]}
				onLoad={() => {
					return <div>loading</div>;
				}}>
				{data.map((item) => (
					<SwiperSlide key={item.id}>
						<LinkElement
							prefetch={false}
							href={item.link.length > 0 ? item.link : "#"}>
							<ImageV2
								src={item.image}
								alt={item.title}
								width={610}
								height={285}
								sizes="(min-width:769px) 610px , 0px"
								// height={285}
								// width={611}
								loading="lazy"
								className="w-full h-full"
								placeholder={"blur"}
								blurDataURL={ImageConstant.BlurDataURL}
							/>
						</LinkElement>
					</SwiperSlide>
				))}
			</Swiper>
		</div>
	);
};

export default BrandSwipper;
