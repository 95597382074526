"use client";

import { getListVideo } from "@/lib/api/shop/video";
// import detectDeviceForServer from "@/utils/detectDeviceServer";

import YoutubeList from "~components/collection/children/YoutubeList";
import CollectionTitle from "~components/collection/children/CollectionTitle";
import { useEffect, useState } from "react";
import useDeviceSize from "@/lib/hooks/useDeviceSize";
import { VideoResponseJson } from "@/types/Video.type";
import { cn } from "@/utils/utils";

export type NamperfumeTVListProps = {
	title?: string;
	className?: string;
	classNameTitle?: string;
};

async function getDataVideos() {
	try {
		const data = await getListVideo({ page: 1, limit: 20, order: "date" });
		return data.data;
	} catch (error) {}
}

export default function NamperfumeTVList({
	title = "namperfume TV",
	className,
	classNameTitle,
}: NamperfumeTVListProps) {
	const { isMobile } = useDeviceSize();
	const [data, setData] = useState<VideoResponseJson>();

	async function init() {
		try {
			const data = await getDataVideos();
			setData(data);
		} catch (error) {}
	}

	useEffect(() => {
		init();
	}, []);

	return (
		<>
			{data && data.success && data.limit > 0 && (
				<div className={cn("flex flex-col gap-3 md:gap-8", className)}>
					<CollectionTitle
						title={title}
						viewMore={false}
						translateKey="namperfume_tv"
						link="https://www.youtube.com/channel/UCSRAPo6ak77LkyEXn8Kon9A"
						className="px-4 md:px-0 "
						target="_blank"
						classNameTitle={classNameTitle}
					/>
					<YoutubeList
						data={data.data || []}
						device={isMobile ? "mobile" : "desktop"}
					/>
				</div>
			)}
		</>
	);
}
