"use client";
import React, { useEffect, useId, useMemo, useRef, useState } from "react";

import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import {
	A11y,
	Autoplay,
	EffectCoverflow,
	EffectCreative,
	EffectCube,
	EffectFade,
	EffectFlip,
	Navigation,
	Pagination,
	Scrollbar,
	FreeMode,
} from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/scss/pagination";

import useWindowSize from "@/lib/hooks/useWindowSize";
import { PaginationOptions, ScrollbarOptions } from "swiper/types";
import SlidePrevIcon from "./icons/SlidePrevIcon";
import SlideNextIcon from "./icons/SlideNextIcon";
import { uniqueId } from "lodash";
import { cn } from "@/utils/utils";
type breakpoints = {
	xxs: s;
	xs: s;
	sm: s;
	md: s;
	lg: s;
	xl: s;
};
type s = {
	spaceBetween: number;
	slidesPerView: number;
};
type responsive<breakpoints> = {
	[Property in keyof breakpoints]: breakpoints[Property];
};
export type SliderProps = {
	id?: number | string;
	dataSource: any[];
	itemRender: (item: any, index: number) => React.ReactNode;
	classNameItemRender?: string;
	isloading?: boolean;
	responsive: responsive<breakpoints>;
	styleWrapper?: React.CSSProperties | undefined;
	spaceBetween?: string | number | undefined;
	slidesPerView?: number | "auto" | undefined;
	navigation?: boolean;
	pagination?: PaginationOptions | undefined;
	scrollbar?: boolean | ScrollbarOptions | undefined;
	allowSlideNext?: boolean | undefined;
	allowSlidePrev?: boolean | undefined;
	allowTouchMove?: boolean | undefined;
	autoplay?: boolean | { delay: number; disableOnInteraction: boolean };
	loop?: boolean;
	widthAutoSlide?: boolean;
	slidesPerGroup?: number;
	styleButton?: React.CSSProperties | undefined;
	styleButton_next?: React.CSSProperties | undefined;
	styleButton_prev?: React.CSSProperties | undefined;
	iconButtonNext?: React.ReactNode;
	iconButtonPrev?: React.ReactNode;

	initialSlide?: number;
	classButtonSlide?: string;
	idSwiper?: string;
	speed?: number | undefined;
	classWrapper?: string;
	centeredSlidesMobile?: boolean;
	centeredSlidesDesktop?: boolean;
	centerInsufficientSlides?: boolean;
	centeredSlidesBounds?: boolean;
	freeMode?: boolean;
	slideActiveClass?: string;
	isResponsive?: boolean;
	styleSlide?: React.CSSProperties;
	onSwiper?: ((swiper: SwiperClass) => void) | undefined;
	onSlideChange?: ((swiper: SwiperClass) => void) | undefined;
	onReachBeginning?: (swiper: any) => void;
	onReachEnd?: (swiper: any) => void;
	onInit?: ((swiper: SwiperClass) => void) | undefined;
	onScroll?: (swiper: SwiperClass, event: WheelEvent) => void;
	handleError?: () => void;
};
const Slider = ({
	id,
	dataSource = [],
	itemRender,
	classNameItemRender,
	isloading = true,
	handleError,
	styleWrapper = { width: "100%" },
	spaceBetween = 4,
	slidesPerView = 6,
	navigation = true,
	pagination,
	scrollbar = false,
	onSwiper = (swiper) => {},
	onSlideChange = (swiper: SwiperClass) => {},
	onInit,
	allowSlideNext = true,
	allowSlidePrev = true,
	allowTouchMove = true,
	loop = false,
	autoplay = {
		delay: 1500,
		disableOnInteraction: false,
	},
	responsive,
	widthAutoSlide = false,
	slidesPerGroup = 1,
	styleButton = {},
	styleButton_next,
	styleButton_prev,
	initialSlide,
	classButtonSlide,
	idSwiper,
	speed = 1500,
	classWrapper,
	centeredSlidesMobile = false,
	centeredSlidesDesktop = false,
	centerInsufficientSlides,
	centeredSlidesBounds,
	freeMode = true,
	slideActiveClass,
	isResponsive = true,
	styleSlide,
	onReachBeginning,
	onReachEnd,
	onScroll,
	iconButtonNext,
	iconButtonPrev,
}: SliderProps) => {
	const swiperRef = useRef<any>();
	const windowSize = useWindowSize();

	const [windowWidth, setWindowWidth] = useState<number>(windowSize.width || 0);

	const [isCentered, setIsCentered] = useState(false);

	const preSelector = useMemo(() => uniqueId(), []);
	const nextSelector = useMemo(() => uniqueId(), []);

	useEffect(() => {
		if (
			swiperRef &&
			swiperRef?.current &&
			typeof initialSlide !== "undefined"
		) {
			swiperRef.current.swiper.slideTo(initialSlide);
		}
	}, [initialSlide]);

	useEffect(() => {
		setWindowWidth(windowSize.width || 0);
	}, [windowSize]);
	// useEffect(() => {
	// 	if (centeredSlidesDesktop && centeredSlidesMobile) {
	// 		setIsCentered(true);
	// 	} else {
	// 		if (width) {
	// 			if (centeredSlidesMobile && width <= 768) {
	// 				setIsCentered(true);
	// 			}
	// 			if (centeredSlidesDesktop && width > 768) {
	// 				setIsCentered(true);
	// 			}
	// 		}
	// 	}
	// }, [width]);
	const ID = useId();

	return (
		<div className={classWrapper} style={{ ...styleWrapper }}>
			{isloading ? (
				<div></div>
			) : dataSource.length <= 0 ? null : (
				<>
					<Swiper
						ref={swiperRef}
						// slideActiveClass={slideActiveClass}
						// id={idSwiper}
						modules={[
							Navigation,
							Pagination,
							Scrollbar,
							A11y,
							Autoplay,
							EffectFade,
							EffectCoverflow,
							EffectFlip,
							EffectCoverflow,
							EffectCube,
							EffectCreative,
							FreeMode,
						]}
						effect="slide"
						freeMode={{ enabled: freeMode, momentum: true }}
						spaceBetween={spaceBetween}
						slidesPerView={slidesPerView}
						loop={loop}
						speed={speed}
						slidesPerGroup={slidesPerGroup}
						initialSlide={initialSlide}
						centeredSlides={
							windowWidth > 768 ? centeredSlidesDesktop : centeredSlidesMobile
						}
						centerInsufficientSlides={centerInsufficientSlides}
						centeredSlidesBounds={centeredSlidesBounds}
						navigation={
							navigation
								? {
										nextEl: `#next-${nextSelector}`,
										prevEl: `#pre-${preSelector}`,
								  }
								: false
						}
						pagination={
							pagination
								? {
										...pagination,
								  }
								: { clickable: false, renderBullet: () => "" }
						}
						scrollbar={
							scrollbar
								? scrollbar
								: { draggable: true, hide: true, enabled: false }
						}
						onSwiper={onSwiper}
						onScroll={onScroll}
						onSlideChange={onSlideChange}
						onSlideChangeTransitionEnd={() => {}}
						allowSlideNext={allowSlideNext}
						allowSlidePrev={allowSlidePrev}
						allowTouchMove={allowTouchMove}
						autoplay={autoplay}
						onReachBeginning={onReachBeginning}
						onReachEnd={onReachEnd}
						onInit={onInit}
						breakpoints={
							isResponsive
								? {
										279: responsive.xxs,
										325: responsive.xs,
										414: responsive.sm,
										768: responsive.md,
										1024: responsive.lg,
										1500: responsive.xl,
								  }
								: undefined
						}>
						{dataSource.map((item: any, index) => {
							return (
								<SwiperSlide
									style={styleSlide}
									key={index}
									className={classNameItemRender}>
									{itemRender(item, index)}
								</SwiperSlide>
							);
						})}
					</Swiper>
					{navigation ? (
						<>
							<button
								id={`next-${nextSelector}`}
								style={{ ...styleButton, ...styleButton_next }}
								className={cn(
									"absolute -right-[20px] top-[50%] -translate-y-[50%] z-10  disabled:cursor-not-allowed disabled:opacity-50 ",
									classButtonSlide
								)}>
								{iconButtonNext ? iconButtonNext : <SlideNextIcon />}
							</button>
							<button
								id={`pre-${preSelector}`}
								style={{ ...styleButton, ...styleButton_prev }}
								className={cn(
									"absolute -left-[20px] top-[50%] -translate-y-[50%]  z-10 disabled:cursor-not-allowed disabled:opacity-50 ",
									classButtonSlide
								)}>
								{iconButtonPrev ? iconButtonPrev : <SlidePrevIcon />}
							</button>
						</>
					) : null}
				</>
			)}
		</div>
	);
};

export default Slider;
