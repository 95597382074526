import { callApiV2 } from "@/lib/config/Api";
import {
  PromotionFilter,
  PromotionListJson,
  PromotionBodyListJson,
  PromotionListFull,
} from "@/types/Promotion.type";
import Helper from "@/utils/helper";


export async function getPromotion(filter: PromotionFilter) {
  const filters: PromotionFilter = {
    ...filter,
    type: filter?.type || "product",
  };
  const params = Helper.convertFilterToParams(filters || {});
  const response = await callApiV2<PromotionListJson>(
    `/promotions/public${params}`,
    "get"
  );
  return response;
}

export async function getPromotionsFull() {


  const response = await callApiV2<PromotionListFull>(
    `/promotions/public`,
    "get"
  );
  return response;
}
export async function getPromotionsBody(
  filter?: Required<Pick<PromotionFilter, "subtotal">>
) {
  const filters: PromotionFilter = {
    ...filter,
    type: "cart",
  };
  const params = Helper.convertFilterToParams(filters || {});
  const response = await callApiV2<PromotionBodyListJson>(
    `/promotions/public${params}`,
    "get"
  );
  return response;
}
export async function getPromotionCoupons() {
  
}
