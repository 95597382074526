"use client";

import React from "react";

import { VideoJson } from "@/types/Video.type";

import Image from "@/components/ui/Image";
import FileModel from "@/common/models/FileModel";
import LinkElement from "@/components/LinkElement";
import PlayIcon from "@/components/icons/PlayIcon";
import ImageConstant from "@/common/constants/image";

type YoutubeCardProps = {
	data: VideoJson;
	heightImage?: number;
};

const YoutubeCard = ({ data, heightImage = 116 }: YoutubeCardProps) => {
	return (
		<div className="w-full">
			<LinkElement
				href={data.video_url + data.video_id}
				target="_blank"
				aria-label="youtube link namperfumetv">
				<div className="w-full relative h-[111px] md:h-[165px]">
					<Image
						src={
							FileModel.getThumbnailFromUrl(
								data?.video_thumbnails?.maxres?.url ||
									data?.video_thumbnails?.high?.url ||
									"",
								293,
								165,
								"resize"
							) || ""
						}
						alt={data.video_title}
						width={320}
						height={180}
						className={"w-full h-[111px] md:h-[165px] object-cover"}
						placeholder={"blur"}
						blurDataURL={ImageConstant.BlurDataURL}
					/>
					<span className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center">
						<PlayIcon className="w-[60px] h-[60px] min-w-[60px]" />
					</span>
				</div>
			</LinkElement>
			<LinkElement
				href={data.video_url + data.video_id}
				target="_blank"
				className="my-[6px] md:my-1 text-sm md:text-base font-semibold md:font-normal h-[57px] md:h-[48px] leading-[1.6] line-clamp-3 md:line-clamp-2 text-gray-500 hover:text-yellow-500 transition-all">
				{data.video_title}
			</LinkElement>
			<p className="text-xs text-gray-400 h-[10px] leading-[10px] line-clamp-1">
				{data.video_view_count} Views
			</p>
		</div>
	);
};

export default YoutubeCard;
