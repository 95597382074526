"use client";
import React, { useCallback, useEffect, useId, useMemo, useState } from "react";
import Slider from "@/components/Slider";
import { ProductJson } from "@/types/Product.type";
import { cn } from "@/utils/utils";
import { Swiper } from "swiper/types";
import usePromotion from "@/lib/hooks/usePromotion";
import ProductCard from "~components/product/ProductCard";
import { PromotionJson } from "@/types/Promotion.type";
import { CalcPricePromotionJson, PricePromotionJson } from "@/types/Cart.type";
import { PromotionResponse } from "@/types/Promotion.type";
import Carousel from "@/components/Carousel";
import { Device } from "@/types/Shop.type";
import { SlideNextIcon, SlidePrevIcon } from "@/components/icons";
import useTranslationApi from "@/lib/hooks/useTranslationApi";

type Props = {
	data: ProductJson[];
	collection_handle?: string;
	className?: string;
	isAddCartPopup?: boolean;
	isAnimate?: boolean;
	navigation?: boolean;
	device?: Device;
	showCountChild?: boolean;
	showWishlist?: boolean;
	pricePreview?: PricePromotionJson;
};

const translate = {
	quick_view: "quick_view",
	loading: "loading",
	add_compared: "add_compared",
};

const CollectionList = ({
	data,
	className,
	collection_handle,
	isAddCartPopup,
	isAnimate = true,
	navigation = true,
	device,
	showCountChild,
	showWishlist,
	pricePreview,
}: Props) => {
	// const productIds =
	// 	data.length > 0
	// 		? data
	// 				.reduce((crr: number[], p) => {
	// 					crr.push(p.id);
	// 					if (p.list_childs.length > 0) {
	// 						crr.concat(p.list_childs);
	// 					}

	// 					return crr;
	// 				}, [])
	// 				.join(",")
	// 		: "";
	const { t } = useTranslationApi(Object.keys(translate));

	const { pricePreview: pricePreviewPromotion, getPricePreviewPromotion } =
		usePromotion({
			filter: { product_ids: "" },
			isUseAll: true,
			skip: true,
		});

	const [previewPromotions, setPreviewPromotions] =
		useState<CalcPricePromotionJson>([]);

	// const getPromotions = useCallback(
	// 	(item: ProductJson) => {
	// 		let result: PromotionJson[] = [];
	// 		const dataFirst = promotionForProductId(item.id);
	// 		if (dataFirst.length > 0) {
	// 			result.push(...dataFirst);
	// 		}

	// 		if (item.list_childs.length > 0) {
	// 			const dataChilds = item.list_childs.reduce(
	// 				(crr: PromotionJson[], item) => {
	// 					const res = promotionForProductId(item);
	// 					if (res.length > 0) {
	// 						crr.push(...res);
	// 					}
	// 					return crr;
	// 				},
	// 				[]
	// 			);
	// 			if (dataChilds.length > 0) {
	// 				result.push(...dataChilds);
	// 			}
	// 		}

	// 		return result;
	// 	},

	// 	[promotions, productIds]
	// );

	const handleRender = (item: ProductJson, index: number) => {
		// const p = promotionForProductId(item.id);
		// const data = getPromotions(item);
		return (
			<ProductCard
				key={index}
				data={item}
				showCountChild={showCountChild}
				showWishlist={showWishlist}
				collectionHandle={collection_handle}
			/>
		);
	};

	const handleGetPromotionPreview = async () => {
		if (data.length > 0) {
			const props: PromotionResponse = {};
			data
				.filter((i) => i.parent_id === 0)
				.forEach((p) => {
					props[p.id] = p.promotions;
				});
			const pricePromotions = await getPricePreviewPromotion(props);
			if (pricePromotions.length > 0) {
				setPreviewPromotions(pricePromotions);
			}
		}
	};
	const handleInitSlide = (swiper: Swiper) => {
		// swiper.wrapperEl.classList.add("translateX-15");
	};

	// useEffect(() => {
	//   handleGetPromotionPreview();
	// }, [data]);

	return (
		<div
			data-aos={isAnimate ? "fade-up" : null}
			className={cn("collection_wrapper w-full  ", className)}>
			{/* <Slider
				id={useId()}
				dataSource={data}
				initialSlide={0}
				isloading={false}
				itemRender={handleRender}
				classNameItemRender="first:ml-4 last:mr-4 md:first:ml-0 md:last:mr-0 h-auto"
				navigation={true}
				speed={1000}
				loop={true}
				autoplay={false}
				slidesPerView={"auto"}
				centeredSlidesDesktop={false}
				centeredSlidesMobile={true}
				classButtonSlide="hidden md:block"
				styleButton_next={{ right: "-30px" }}
				styleButton_prev={{ left: "-30px" }}
				styleSlide={{
					height: "auto"
				}}
				responsive={{
					xxs: { slidesPerView: 2, spaceBetween: 0 },
					xs: { slidesPerView: 2, spaceBetween: 0 },
					sm: { slidesPerView: 2, spaceBetween: 0 },
					md: { slidesPerView: 4, spaceBetween: 0 },
					lg: { slidesPerView: isAddCartPopup ? 4 : 6, spaceBetween: 0 },
					xl: { slidesPerView: isAddCartPopup ? 4 : 6, spaceBetween: 0 },
				}}
			/> */}
			<Carousel
				data={data}
				itemSlider={handleRender}
				freeMode={true}
				breakpoints={{
					0: { slidesPerView: 2, spaceBetween: 0 },
					375: { slidesPerView: 2, spaceBetween: 0 },
					414: { slidesPerView: 2, spaceBetween: 0 },
					640: { slidesPerView: 2.3, spaceBetween: 0 },
					768: { slidesPerView: 4, spaceBetween: 12 },
					1024: { slidesPerView: isAddCartPopup ? 4 : 6, spaceBetween: 12 },
					1200: { slidesPerView: isAddCartPopup ? 4 : 6, spaceBetween: 12 },
				}}
				loop={true}
				centeredSlides={{ desktop: false, mobile: true }}
				// classNameItemRender="first:ml-4 last:mr-4 md:first:ml-0 md:last:mr-0"
				navigation={navigation}
				navigationButton={{
					iconNext: <SlideNextIcon className="mt-[-60px]" />,
					iconPrev: <SlidePrevIcon className="mt-[-60px]" />,
				}}
				device={device}
			/>
		</div>
	);
};

export default CollectionList;
