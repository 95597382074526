"use client";

import React from "react";

import { Device } from "@/types/Shop.type";
import { BrandJson } from "@/types/Brand.type";

import BlogCard from "./BlogCard";
import Carousel from "@/components/Carousel";
import { SlideNextIcon, SlidePrevIcon } from "@/components/icons";

type Props = {
	data: BrandJson[];
	device: Device;
};

const BlogList = ({ data, device }: Props) => {
	const handleRender = (item: BrandJson, index: number) => {
		return <BlogCard key={item.id} data={item} device={device} />;
	};

	// const onReachBeginning = async () => {
	// 	const collection = await getListStories({
	// 		page: page + 1,
	// 		limit: 20,
	// 		collection_id: collection_id,
	// 	});
	// 	if (data) {
	// 		setDataSource([...collection.items, ...dataSource]);
	// 		setPage((prev) => prev + 1);
	// 	}
	// };

	// const onReachEnd = async () => {
	// 	const collection = await getListStories({
	// 		page: page + 1,
	// 		limit: 20,
	// 		collection_id: collection_id,
	// 	});
	// 	if (data) {
	// 		setDataSource([...dataSource, ...collection.items]);
	// 		setPage((prev) => prev + 1);
	// 	}
	// };

	return (
		<div className="h-full w-full" data-aos="fade-up">
			{/* <Slider
          dataSource={dataSource}
          initialSlide={0}
          isloading={false}
          itemRender={handleRender}
          classNameItemRender="first:ml-4 last:mr-4 md:first:ml-0 md:last:mr-0"
          navigation={true}
          speed={4000}
          loop={true}
          autoplay={false}
          slidesPerGroup={1}
          slidesPerView={0}
          allowSlideNext={true}
          allowSlidePrev={true}
          freeMode={true}
          allowTouchMove={true}
          // setButtonNext="blog_slide_next"
          // setButtonPrev="blog_slide_prev"
          classButtonSlide="hidden md:block"
          styleButton_next={{ right: "-30px" }}
          styleButton_prev={{ left: "-30px" }}
          responsive={{
            xxs: { slidesPerView: 2, spaceBetween: 12 },
            xs: { slidesPerView: 2, spaceBetween: 12 },
            sm: { slidesPerView: 2, spaceBetween: 12 },
            md: { slidesPerView: 4, spaceBetween: 16 },
            lg: { slidesPerView: 6, spaceBetween: 16 },
            xl: { slidesPerView: 6, spaceBetween: 16 },
          }}
          centeredSlidesDesktop={false}
          centeredSlidesMobile={true}
          // onReachBeginning={onReachBeginning}
          // onReachEnd={onReachEnd}
        /> */}

			<Carousel
				data={data}
				itemSlider={handleRender}
				loop={true}
				navigation={true}
				centeredSlides={{ desktop: false, mobile: true }}
				breakpoints={{
					0: { slidesPerView: 2, spaceBetween: 12 },
					640: { slidesPerView: 2, spaceBetween: 12 },
					768: { slidesPerView: 4, spaceBetween: 12 },
					1024: { slidesPerView: 4, spaceBetween: 12 },
					1200: { slidesPerView: 4, spaceBetween: 12 },
				}}
				device={device}
				classNameItemRender="first:ml-0 last:mr-0 md:first:ml-0 md:last:mr-0"
				navigationButton={{
					iconNext: <SlideNextIcon className="mt-[-9px]" />,
					iconPrev: <SlidePrevIcon className="mt-[-9px]" />,
				}}
			/>
		</div>
	);
};

export default BlogList;
