"use client";

import React, { useState } from "react";

import { AboutJson, TabsType } from "../About";

import Image from "@/components/ui/Image";
import ArrowRightIcon from "@/components/icons/ArrowRightIcon";
import useTranslationApi from "@/lib/hooks/useTranslationApi";
import LinkElement from "@/components/LinkElement";
import { cn } from "@/utils/utils";

type Props = {
	//   data: AboutJson[];
	data: TabsType[];
	contentProps?: {
		className?: string;
	};
};

const Tabs = ({ data, contentProps }: Props) => {
	const { t } = useTranslationApi(["about_namperfume"]);
	const [selected, setSelected] = useState<number>(0);

	const handleSelectChange = (selectedValue: number) => {
		setSelected(selectedValue);
	};
	return (
		<>
			<div className="bg-gray-200 h-[363px] pt-[80px] pb-[40px]">
				<div className="section_container px-0">
					<h2 className="text-gray-500 font-[500] mb-[100px] text-center md:font-noto text-xl md:text-4xl min-h-[40px] leading-[40px]">
						{/* Về namperfume */}
						{t("about_namperfume")}
					</h2>
					{/* Tab navigation */}
					<div
						className={`flex mx-auto flex-nowrap overflow-scroll
            md:overflow-y-hidden gap-8 items-center md:justify-around hideScrollBarDesktop ${
							data.length === 5
								? " md:max-w-[815px]"
								: data.length === 3
								? "justify-center md:max-w-[620px]"
								: ""
						} ${data.length > 3 ? "pl-4" : ""}`}>
						{data.length > 0 &&
							data.map((item, index) => {
								return (
									<div
										key={index}
										onClick={() => handleSelectChange(index)}
										className="flex flex-col justify-center items-center cursor-pointer">
										<div
											className={`bg-white flex justify-center items-center h-[72px] w-[72px] border-[3px] rounded-full ${
												selected === index ? " border-gray-500" : "border-white"
											}`}>
											{item.nav.icon}
											{/* <Image
                        alt={"photo"}
                        width={500}
                        height={500}
                        src={item.icon || ""}
                        className={
                          "h-[40px] w-[40px] object-contain object-center"
                        }
                      /> */}
										</div>
										<p
											className={`w-full text-center mt-[14px] text-gray-500 text-xs min-h-[12px] leading-[12px] whitespace-nowrap ${
												selected === index ? "font-bold text-gray-500" : ""
											}`}>
											{item.nav.title}
										</p>
									</div>
								);
							})}
					</div>
				</div>
			</div>
			<div
				className={cn(
					`w-full md:container flex items-center justify-center text-center px-4 md:px-0`,
					contentProps?.className
				)}>
				{/* <div className="text-gray-500 w-full pt-[80px] pb-[40px] flex flex-col items-center text-center animate-fade">
          <div dangerouslySetInnerHTML={{ __html: data[selected].content }} />
        </div> */}
				<div
					key={selected}
					className="text-gray-500 w-full pt-[80px] pb-[40px] flex flex-col items-center text-center animate-fade">
					<h3 className="text-xl font-medium min-h-[30px] leading-[1.6]">
						{data[selected].body.title}
					</h3>
					<div className="mt-6 mb-[80px] flex flex-col gap-[18px] md:gap-5 max-w-[600px] text-sm md:text-base leading-[1.6]">
						{data[selected].body.content}
					</div>
					<LinkElement
						href={data[selected].body.link}
						className="flex items-center">
						<span className="text-sm leading-[1.6] font-bold uppercase">
							{data[selected].body.btnLabel}
						</span>
						<ArrowRightIcon className="w-5 h-5 min-w-5" />
					</LinkElement>
				</div>
			</div>
		</>
	);
};

export default Tabs;
