import Helper from "@/utils/helper";
import { callApiV2 } from "@/lib/config/Api";
import { FilterVideo, VideoResponseJson } from "@/types/Video.type";

export async function getListVideo(filters?: FilterVideo) {
  const apiParams = Helper.convertFilterToParams(filters || {});
  const res = await callApiV2<VideoResponseJson>(
    `/collections/public/youtube${apiParams}`,
    "get"
  );
  return res;
}
