"use client";
import { getPromotion } from "@/lib/api/shop/promotion";
import {
	PromotionFilter,
	PromotionJson,
	PromotionListJson,
	PromotionResponse,
} from "@/types/Promotion.type";
import { useCallback, useEffect, useMemo, useState } from "react";
import { calculatorPriceFromPromotion } from "../api/shop/cart";
import {
	CalcPricePromotionJson,
	CartPromotion,
	PriceProductPromotionProps,
	PricePromotionJson,
} from "@/types/Cart.type";
import { useAppSelector } from "@/redux/hook";
import BaseApi from "@/common/api/BaseApi";

type Props = {
	filter?: PromotionFilter;
	skip?: boolean;
	isUseAll?: boolean;
	isGetPricePreview?: boolean;
};

export default function usePromotion({
	filter,
	skip,
	isUseAll = false,
	isGetPricePreview = false,
}: Props) {
	const cartId = useAppSelector((state) => state.CartReducer.cartDetail)?.id;

	const [promotions, setPromotions] = useState<PromotionListJson | null>(null);

	const [previewPromotion, setPreviewPromotion] =
		useState<CalcPricePromotionJson>([]);

	const [filters, setFilters] = useState(filter);
	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState<string[]>([]);

	const getDataPromotion = useCallback(async () => {
		if (filter?.product_ids) {
			try {
				const res = await getPromotion(filter || {});
				setPromotions(res.data);
				if (isGetPricePreview) {
					const pricePreview = await getPricePreviewPromotion(res.data.data);
					if (pricePreview.length > 0) {
						setPreviewPromotion(pricePreview);
					}
				}
			} catch (error) {
				setErrors(BaseApi.handleError(error).errors);
			}
		}
	}, [filters, skip]);

	const getPricePreviewPromotion = async (
		props: PromotionResponse,
		quantity?: number,
		cart_id?: string
	) => {
		const id = cart_id || cartId;

		if (!id) {
			return [];
		}
		let result: CalcPricePromotionJson = [];
		const data: PriceProductPromotionProps[] = [];
		const quantityFinal = quantity || 1;
		if (Object.keys(props).length > 0) {
			for (const key in props) {
				if (props[key] && props[key].length > 0) {
					const promotionData: CartPromotion[] = props[key]
						.filter((pro) => pro.status)
						.map((p) => {
							const is_use = isUseAll
								? 1
								: typeof p.is_use !== "undefined"
								? p.is_use
								: 1;
							return {
								is_use: is_use,
								item_quantity: quantityFinal,
								product_id: p.req_productid,
								promotion_detail: p,
								promotion_id: p.campaign_info.id,
								sale_promotion_id: p.id,
							};
						});
					if (promotionData.length > 0) {
						data.push({
							item_quantity: quantityFinal,
							product_id: typeof key !== "number" ? Number(key) : key,
							cart_id: id,
							promotions: promotionData,
						});
					}
				}
			}
		}
		if (data.length > 0) {
			try {
				const res = await calculatorPriceFromPromotion({ data: data });
				if (res.data.length > 0) {
				}
				result = res.data;
			} catch (error) {}
		}
		return result;
	};

	// const promotionIsRunning = useMemo(() => {
	// 	let result: PromotionJson[] = [];
	// 	const event = promotions.has_event;
	// 	const eventData = promotions.data;
	// 	if (event.length > 0 && eventData.length > 0) {
	// 		eventData.forEach((e) => {
	// 			if (event.includes(e.id)) {
	// 				result.concat(e);
	// 			}
	// 		});
	// 	}
	// 	return result;
	// }, [promotions]);

	// const promotionForProductId = useCallback(
	// 	(id: number) => {
	// 		let result: PromotionJson[] = [];

	// 		const findSalePromotionFromId = (data: SalePromotion[], id: number) => {
	// 			return data.reduce((cal: SalePromotion[], item) => {
	// 				if (
	// 					typeof item.product_ids_in === "string" &&
	// 					item.product_ids_in.includes(id.toString())
	// 				) {
	// 					cal.push(item);
	// 				}
	// 				return cal;
	// 			}, []);
	// 		};

	// 		const pushPromotionInResult = (
	// 			promotions: PromotionJson[],
	// 			id: number
	// 		) => {
	// 			promotions.forEach((p) => {
	// 				const allSalePromotion = findSalePromotionFromId(
	// 					p.sale_promotions,
	// 					id
	// 				);
	// 				if (allSalePromotion.length > 0) {
	// 					result.push({ ...p, sale_promotions: allSalePromotion });
	// 				}
	// 			});
	// 		};

	// 		if (promotions.data.length > 0 && id > 0) {
	// 			if (promotionIsRunning.length > 0) {
	// 				// Nếu các sản phẩm đều có 1 event nào đó
	// 				pushPromotionInResult(promotionIsRunning, id);

	// 				if (promotionIsRunning.length < promotions.data.length) {
	// 					// Nếu tất cả product trong filter vẫn còn chương trình khác nhưng không phải toàn bộ
	// 					const promotionRemaning = promotions.data.filter((i) =>
	// 						promotionIsRunning.some((pr) => pr.id === i.id)
	// 					);

	// 					if (promotionRemaning.length > 0) {
	// 						pushPromotionInResult(promotionRemaning, id);
	// 					}
	// 				}
	// 			} else {
	// 				// Nếu các sản phẩm có thể có hoặc không có event
	// 				pushPromotionInResult(promotions.data, id);
	// 			}
	// 		}

	// 		return result;
	// 	},
	// 	[promotions]
	// );

	const promotionForProductId = (
		product_id: number,
		data?: PromotionListJson
	) => {
		const idToString = product_id.toString();
		const dataFind: PromotionListJson | null = data ? data : promotions;

		let result: PromotionJson[] = [];

		const conditions = dataFind?.data.hasOwnProperty(idToString) || false;
		if (conditions && dataFind) {
			// const datatypePercent = dataFind.data[idToString].filter(
			// 	(p) => p.discount_type !== "percent"
			// );
			const promotionFromId = [
				...dataFind.data[idToString]
					.filter((p) => p.status === true)
					.map((i) => {
						return {
							...i,
							is_use: 1,
						};
					}),
			];

			promotionFromId.length > 0 && result.push(...promotionFromId);
		}
		result.sort((a, b) => {
			if (a.priority > b.priority) {
				return 1;
			} else {
				return -1;
			}
		});

		return result;
	};

	const pricePreview = (
		productId: number,
		dataFind?: CalcPricePromotionJson
	) => {
		const previewPromotions = dataFind ? dataFind : previewPromotion;
		let result: PricePromotionJson = {
			discount: 0,
			item_quantity: 0,
			price_final: 0,
			price_Unit: 0,
			product_id: 0,
		};
		const findResult = previewPromotions.find(
			(i) => i.product_id === productId
		);
		if (findResult) {
			result = findResult;
		}

		return result;
	};

	const getIdsPrmotionIsUse = (promotion?: PromotionJson[]) => {
		let result: number[] = [];
		if (promotion && promotion.length > 0) {
			result = promotion.filter((p) => p?.is_use === 1).map((i) => i.id);
		}
		return result;
	};

	useEffect(() => {
		if (!skip) {
			getDataPromotion();
		}
	}, [getDataPromotion]);

	return {
		promotions,
		loading,
		errors,
		promotionForProductId,
		getIdsPrmotionIsUse,
		setFilters,
		pricePreview,
		getPricePreviewPromotion,
	};
}
