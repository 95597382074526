"use client";
import Link from "next/link";
import { usePathname } from "next/navigation";
import React, { useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "@/redux/hook";
import {
	setIsShowSearchMobile,
	setNotifyMobile,
	setSidebarMobile,
} from "@/redux/features/ShopSlice";

import {
	HeartFilledIcon,
	HeartIcon,
	NamperfumeFilledIcon,
	NamperfumeIcon,
	SearchFilledIcon,
	SearchIcon,
	TagFilledIcon,
	UserFilledIcon,
	UserIcon,
} from "@/components/icons";
import TagIcon from "@/components/icons/TagIcon";
import Website from "@/common/constants/webstite";
import { useSession } from "next-auth/react";
import LinkElement from "@/components/LinkElement";
import useWishlistV2 from "@/lib/hooks/useWishlistV2";
import useCustomer from "@/lib/hooks/useCustomer";

type NavItem = {
	title: string;
	icon: React.ReactNode;
	iconActive: React.ReactNode;
	link?: string;
};

const navigationList: NavItem[] = [
	{
		title: "Home",
		iconActive: <NamperfumeFilledIcon />,
		icon: <NamperfumeIcon />,
		link: "/",
	},
	{
		title: "Tìm kiếm",
		iconActive: <SearchFilledIcon />,
		icon: <SearchIcon />,
	},
	{
		title: "Thương hiệu",
		iconActive: <TagFilledIcon />,
		icon: <TagIcon />,
		link: Website.LINK.PAGE.BRAND_FAVORITE,
	},
	{
		title: "Yêu thích",
		iconActive: <HeartFilledIcon className="text-red-500" />,
		icon: <HeartIcon />,
		link: Website.LINK.PAGE.WISHLIST,
	},
	{
		title: "Tôi",
		iconActive: <UserFilledIcon className="w-[24px] h-[24px] min-w-[24px]" />,
		icon: <UserIcon />,
	},
	{
		title: "Đăng nhập",
		iconActive: <UserFilledIcon className="w-[24px] h-[24px] min-w-[24px]" />,
		icon: <UserIcon />,
		link: Website.LINK.PAGE.LOGIN,
	},
];

export default function HeaderNavigation() {
	const pathname = usePathname();
	const dispatch = useAppDispatch();
	// const userInfo = useAppSelector((state) => state.LoyaltyReducer.user);
	// const { data } = useSession();
	 const {data : user}= useCustomer()
	//states
	const isShowSearchMobile = useAppSelector(
		(state) => state.ShopReducer.isShowSearchMobile
	);
	const sidebarMobile = useAppSelector(
		(state) => state.ShopReducer.sidebarMobile
	);
	const [logged, setLogged] = useState<boolean>(false);

	const [selected, setSelected] = useState<number>(0);
	const [currentPage, setCurrentPage] = useState<string>(pathname);

	const { wishlist } = useWishlistV2();

	const wishlistShow = wishlist.filter((item) => item.item_type == 1);

	//functions
	const handleSelectChange = (selectedValue: any) => {
		if (selectedValue !== selected) {
			setSelected(selectedValue);
		} else if (selectedValue === 1 && !isShowSearchMobile) {
			setSelected(1);
		} else if (selectedValue === 4 && !sidebarMobile) {
			setSelected(4);
		} else {
			handleCurrentPage(currentPage);
		}
	};

	const toggle = (open: "search" | "me" | "close") => {
		if (open === "search") {
			dispatch(setIsShowSearchMobile(!isShowSearchMobile));
			dispatch(setSidebarMobile(false));
		} else if (open === "me") {
			dispatch(setSidebarMobile(!sidebarMobile));
			dispatch(setIsShowSearchMobile(false));
		} else {
			dispatch(setIsShowSearchMobile(false));
			dispatch(setSidebarMobile(false));
		}
		dispatch(setNotifyMobile(false));
		handleCurrentPage(currentPage);
	};

	const handleCurrentPage = (currentPage: string) => {
		if (currentPage === Website.LINK.PAGE.WISHLIST) {
			setSelected(3);
		} else if (
			currentPage === Website.LINK.PAGE.BRAND ||
			currentPage === Website.LINK.PAGE.BRAND_FAVORITE
		) {
			setSelected(2);
		} else if (currentPage === "/") {
			setSelected(0);
		} else {
			setSelected(-1);
		}
	};

	useEffect(() => {
		pathname !== currentPage && setCurrentPage(pathname);
		handleCurrentPage(pathname);
	}, [pathname]);

	useEffect(() => {
		if (user && user.id > 0) {
			setLogged(true);
		} else {
			setLogged(false);
		}
	}, [user?.id]);

	return (
		<div className="fixed bottom-0 left-0 right-0 md:hidden block z-[9999] h-[55px] border-t-[1px] border-gray-200 bg-white shadow-2xl tabs">
			<ul className="flex items-center h-[55px] px-4 justify-between">
				{navigationList
					.filter((item) => {
						if (item.title === "Tôi") {
							if (logged) {
								return item;
							}
						} else if (item.title === "Đăng nhập") {
							if (!logged) {
								return item;
							}
						} else {
							return item;
						}
					})
					.map((item, index) => (
						<li
							key={index}
							onClick={() => handleSelectChange(index)}
							className="transition-all">
							{item.link ? (
								<LinkElement
									href={item.link}
									className="flex flex-col justify-center items-center transition-all"
									onClick={() => {
										toggle("close");
									}}>
									<span className="h-[24px] relative">
										{selected === index ? item.iconActive : item.icon}
										{item.link && item.link === Website.LINK.PAGE.WISHLIST && (
											<span className="text-[8px] w-[15px] h-[15px] rounded-full text-white bg-red-500 font-bold absolute right-0 bottom-0 translate-x-[3px] translate-y-[-1px]  flex justify-center  items-center">
												{wishlistShow.length}
											</span>
										)}
									</span>
									<p
										className={`text-sm h-[15px] leading-[15px] ${
											selected === index ? "text-red-500" : "text-gray-500"
										}`}>
										{item.title}
									</p>
								</LinkElement>
							) : (
								<span
									className="flex flex-col justify-center items-center transition-all"
									onClick={() =>
										item.title === "Tôi"
											? toggle("me")
											: item.title === "Tìm kiếm"
											? toggle("search")
											: toggle("close")
									}>
									<span className="h-[24px]">
										{selected === index ? item.iconActive : item.icon}
									</span>
									<p
										className={`text-sm h-[15px] leading-[15px] ${
											selected === index ? "text-red-500" : "text-gray-500"
										}`}>
										{item.title}
									</p>
								</span>
							)}
						</li>
					))}
			</ul>
		</div>
	);
}
