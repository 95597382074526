"use client";
import React, { useId } from "react";
import { VideoJson } from "@/types/Video.type";
import YoutubeCard from "./YoutubeCard";
import Slider, { SliderProps } from "@/components/Slider";
import Carousel from "@/components/Carousel";
import { Device } from "@/types/Shop.type";
import { SlideNextIcon, SlidePrevIcon } from "@/components/icons";

type Props = {
	data: VideoJson[];
	renderItem?: (item: VideoJson) => React.ReactNode;
	slideProps?: Pick<
		SliderProps,
		| "responsive"
		| "centeredSlidesDesktop"
		| "centeredSlidesMobile"
		| "classNameItemRender"
	>;
	device?: Device;
};

const YoutubeList = ({ data, renderItem, slideProps, device }: Props) => {
	const handleRender = (item: VideoJson) => {
		return renderItem ? (
			renderItem(item)
		) : (
			<YoutubeCard key={item.video_id} data={item} />
		);
	};
	const classItem = slideProps?.classNameItemRender
		? slideProps.classNameItemRender
		: slideProps?.centeredSlidesMobile || slideProps?.centeredSlidesDesktop
		? "first:ml-4 last:mr-4 md:first:ml-0 md:last:mr-0"
		: "";

	return (
		<div data-aos="fade-up">
			<div className="h-full w-full">
				{/* <Slider
					id={useId()}
					dataSource={data}
					initialSlide={0}
					isloading={false}
					itemRender={handleRender}
					classNameItemRender={classItem}
					navigation={true}
					speed={1000}
					loop={true}
					autoplay={false}
					slidesPerView={"auto"}
					centeredSlidesDesktop={slideProps?.centeredSlidesDesktop}
					centeredSlidesMobile={slideProps?.centeredSlidesMobile}
					classButtonSlide="hidden md:block"
					styleButton_next={{ right: "-30px" }}
					styleButton_prev={{ left: "-30px" }}
					responsive={
						slideProps
							? { ...slideProps.responsive }
							: {
									xxs: { slidesPerView: 2, spaceBetween: 12 },
									xs: { slidesPerView: 2, spaceBetween: 12 },
									sm: { slidesPerView: 2, spaceBetween: 12 },
									md: { slidesPerView: 4, spaceBetween: 16 },
									lg: { slidesPerView: 6, spaceBetween: 16 },
									xl: { slidesPerView: 6, spaceBetween: 16 },
							  }
					}
				/> */}
				<Carousel
					data={data}
					itemSlider={handleRender}
					loop={true}
					navigation={true}
					centeredSlides={{ desktop: false, mobile: true }}
					breakpoints={{
						0: { slidesPerView: 2, spaceBetween: 12 },
						640: { slidesPerView: 2, spaceBetween: 12 },
						768: { slidesPerView: 4, spaceBetween: 12 },
						1024: { slidesPerView: 4, spaceBetween: 12 },
						1200: { slidesPerView: 4, spaceBetween: 12 },
					}}
					device={device}
					classNameItemRender="md:first:ml-0 md:last:mr-0"
					navigationButton={{
						iconNext: <SlideNextIcon className="mt-[-15px]" />,
						iconPrev: <SlidePrevIcon className="mt-[-15px]" />,
					}}
				/>
			</div>
		</div>
	);
};

export default YoutubeList;
